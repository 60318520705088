<template>
  <div class="d-flex flex-column">
    <ModalCustomerPayment
      v-if="showCustomerPaymentFlag"
      :show="showCustomerPaymentFlag"
      :docNo="tabDetails.docNo"
      title="Customer Payment"
      @showCustomerPayment="showCustomerPayment"
    />
    <ModalIssueaRelease
      v-if="showIssueReleaseFlag"
      :show="showIssueReleaseFlag"
      :docNo="tabDetails.docNo"
      :tabDetails="tabDetails"
      title="New Record Release Note"
      @showIssueRelease="showIssueRelease"
    />
    <v-container class="dark-blue" fluid>
      <DetailsActions
        class="border-bottom pb-2"
        @showCustomerPayment="showCustomerPayment"
        @showIssueRelease="showIssueRelease"
      />
    </v-container>

    <v-container class="dark-blue pa-1" fluid>
      <v-row v-if="details[0]">
        <v-col
          v-for="detail in detailsFields"
          :key="detail.title"
          :cols="detail.cols || ''"
          class="pa-1"
          :style="{
            height: 'auto',
          }"
        >
          <v-card elevation="5" class="pa-0 dark-blue fill-height">
            <v-list-item-content class="ma-0 pa-2 d-flex fill-height">
              <v-list-item-title class="ma-0 pa-2 title">
                {{ detail.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="ma-0 px-2 subtitle" tag="span">
                <v-chip class="ma-0" color="primary" v-if="detail.title === 'Status'">
                  {{ detail.value }}
                </v-chip>
                <template v-else>
                  {{ detail.value }}
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Quote
      v-if="tabDetails.docType === 'Invoice' || tabDetails.vendStepDescr === 'Quote'"
      class="elevation-5 my-2 dark-blue"
    />
    <v-tabs
      v-model="tab"
      class="pa-0 ma-0"
      background-color="dark_blue"
      left
      leave-absolute
    >
      <v-tab v-for="tabValue in tabValues" :key="tabValue.title" class="tab">
        {{ tabValue.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :transition="false" v-for="tabValue in tabValues" :key="tabValue.title">
        <v-row>
          <v-container fluid class="pa-0 ma-1" :style="{ maxHeight: '600px' }">
            <component :is="tabValue.component" v-bind="tabValue.props" />
          </v-container>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DetailsActions from "./DetailsActions.vue";
import { parseDate } from "@/helpers/mocks/dates";
import TableDetails from "./TableDetails.vue";
import TableActivities from "@/components/OM/TableActivities.vue";
import TableDocuments from "@/components/OM/TableDocuments.vue";
import Quote from "./DetailsQuote.vue";
import ModalCustomerPayment from "@/components/OM/ModalCustomerPayment.vue";
import ModalIssueaRelease from "@/components/OM/ModalIssueRelease/ModalIssueaRelease";

import { mapState } from "vuex";
export default {
  props: ["details"],
  components: {
    DetailsActions,
    TableDetails,
    TableActivities,
    TableDocuments,
    Quote,
    ModalCustomerPayment,
    ModalIssueaRelease,
  },
  watch: {
    details() {
      if (this.details[0]) {
        console.log("this.details[0]", this.details[0]);
        Object.keys(this.values).map((value) => {
          this.getValues(value);
        });
      }
    },
  },

  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
    }),
    tabDetails() {
      return this.tabSelected.details;
    },
    detailsFields() {
      let values = [
        // { title: "type", value: this.values["docTypeVendDesc"] },
        { title: "Internal ID", value: this.values["id"] },
        { title: "Doc #", value: this.values["invoice"] },
        // { title: "Nereus Order #", value: this.values["nereus_no"] },
        // { title: "Status", value: this.values["custStepDescr"] },
        { title: "Qty", value: this.values["vit_qty"] },
        { title: "Total Amount", value: this.values["vit_vendorTotal"] },
        { title: "Created By", value: this.values["createdBy"] },
        { title: "Created at", value: this.values["createdAt"] },
        // { title: "Customer Order Ref#", value: "-" },
      ];

      return values;
    },
    tabValues() {
      return [
        // "Items",
        // "Attachments",
        // "Activity",

        {
          title: "Items",
          component: TableDetails,
          props: {
            details: this.details,
          },
        },
        {
          title: "Attachments",
          component: TableDocuments,
          props: {
            details: this.details,
          },
        },
        {
          title: "Activity",
          component: TableActivities,
          props: {
            details: this.details,
          },
        },
      ];
    },
  },
  data() {
    return {
      tab: null,

      values: {
        id: "",
        docType: "",
        invoice: "",
        vendStepDescr: "",
        vit_qty: "",
        vit_vendorTotal: "",
        createdBy: "",
        createdAt: "",
        customerOrderRef: "",
      },
      showCustomerPaymentFlag: false,
      showIssueReleaseFlag: false,
    };
  },
  methods: {
    getValues(value) {
      switch (value) {
        case "id":
          this.values[value] = this.details[0].id;
          break;
        case "docType":
          this.values[value] = value === "RFQ" ? "Quote" : this.details[0].docType;
          break;
        case "invoice":
          this.values[value] =
            value === "RFQ" || value === "Quote" ? "" : this.details[0].docNo;
          break;
        case "vendStepDescr":
          this.values[value] = this.details[0].vendStepDescr;

          break;
        case "vit_qty":
          this.values[value] = this.tabDetails.vit_qty;
          break;
        case "vit_vendorTotal":
          this.values[value] = `$  ${parseFloat(this.tabDetails.vit_vendorTotal).toFixed(
            2
          )}`;
          break;
        case "createdBy":
          this.values[value] = this.details[0].origin_login_name
            ? this.details[0].origin_login_name
            : "[ Nereus Sales Team ]";
          break;
        case "createdAt":
          this.values[value] = parseDate(this.details[0].docDate);
          break;

        default:
          break;
      }
    },
    showCustomerPayment(flag) {
      this.showCustomerPaymentFlag = flag;
    },
    showIssueRelease(flag) {
      this.showIssueReleaseFlag = flag;
    },
  },
};
</script>

<style scoped>
.pa-l-1 {
  padding-left: 1rem;
}

.ma-t {
  margin-top: 0.5rem;
  /* margin-bottom: 0.25rem; */
  padding-left: 1rem;
  padding-right: 1rem;
}

.title {
  font-size: 1rem !important;
  line-height: 1rem;
  text-align: center !important;
}
</style>

<style scoped lang="scss">
.title {
  font-weight: bold;
  color: #3284f4;
  text-transform: capitalize;
}
.border-bottom {
  border-bottom: thin solid #3284f4;
}
.subtitle {
  white-space: initial;
  text-align: center;
  font-weight: 400;
}
</style>
